import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import { useAuth } from "../../../services/auth";
import { useTenancy } from "../../../services/tenancy";
import { useRoles } from "../../../services/roles";
// @ts-ignore
import { MediaLibraryCollection } from "media-library-pro-react-collection";
import "media-library-pro-styles";
export default function InputFile({ label, files, setFiles, info, disabled, error, errors, isError, setIsError, multiple, maxItems, }) {
    var _a, _b, _c;
    const { t } = useTranslation();
    const auth = useAuth();
    const tenancy = useTenancy();
    const roles = useRoles();
    const onChange = (changedFiles) => {
        setFiles(Object.values(changedFiles));
        if (isError && errors && error && setIsError && (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error)))
            setIsError(false);
    };
    return (_jsxs(Fragment, { children: [_jsx(Box, Object.assign({ sx: { paddingBottom: 1 } }, { children: _jsx(Typography, Object.assign({ variant: "body1" }, { children: label })) })), _jsx(Box, Object.assign({ sx: {
                    cursor: "pointer",
                    pointerEvents: disabled ? "none" : "auto",
                    opacity: disabled ? 0.5 : 1,
                    ".media-library-input": {
                        pointerEvents: "none",
                        backgroundColor: "transparent",
                        padding: 0,
                    },
                    ".media-library-item": {
                        cursor: "default",
                    },
                    ".media-library-row-remove": {
                        display: process.env.APP_ID === "mcd" && roles.isAgent ? "none" : "flex",
                    },
                } }, { children: _jsx(MediaLibraryCollection, { initialValue: files, uploadDomain: process.env.API_URL, routePrefix: auth.isPublic
                        ? `api/tenant/${process.env.APP_ENV === "local" ? "1" : (_a = tenancy.client) === null || _a === void 0 ? void 0 : _a.id}`
                        : `api/tenant/${(_b = tenancy.client) === null || _b === void 0 ? void 0 : _b.id}`, multiple: multiple, maxItems: maxItems, validationRules: {
                        accept: [
                            "image/*",
                            "application/pdf",
                            "application/msword",
                            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                            "application/vnd.ms-excel",
                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                            "application/vnd.ms-outlook",
                        ],
                        maxSizeInKB: (_c = process.env.MAX_FILE_SIZE_KB) !== null && _c !== void 0 ? _c : 5120,
                    }, onChange: onChange, translations: t("fields.field-form.media-library", { returnObjects: true }), fileTypeHelpText: t("fields.field-form.file-type"), sortable: false }) })), isError && errors && error && (errors === null || errors === void 0 ? void 0 : errors.hasOwnProperty(error)) ? (_jsx(FormHelperText, Object.assign({ error: true }, { children: errors[error][0] }))) : (info && _jsx(FormHelperText, { children: info }))] }));
}
